<template>
  <div class="crud-table">
    <el-row class="page-buttons">
      <div class="buttons-left">
        <slot name="buttons-top-left" v-bind="{ loading }" />
        <editor-button v-if="allowActions && allowCreate && hasCreatePermission" action="add" @click="handleCreate" />
        <editor-button
          v-if="clearFiltersVisible"
          :disabled="!filtersActive"
          action="clearFilters"
          @click="clearAllFilters"
        />
      </div>
      <div class="buttons-right">
        <slot name="buttons-top-right" v-bind="{ loading }" />
        <editor-button v-if="!isEmbedded" action="cancel" @click="close" />
      </div>
    </el-row>
    <pagination
      v-if="usePagination"
      v-show="numberOfItems > 0"
      :total="numberOfItems"
      :page.sync="page"
      :limit.sync="limit"
      @pagination="refreshList"
    />
    <el-table
      ref="crudTable"
      v-loading="loading"
      :data="items"
      :show-summary="showSummary"
      :summary-method="summaryMethod"
      border
      fit
      highlight-current-row
      style="width: 100%"
      @row-dblclick="rowDblClick"
      @row-contextmenu="openContextMenu"
      @sort-change="sortChange"
      @current-change="handleCurrentChange"
    >
      <div v-for="item in items" :key="item.id">
        {{ item }}
      </div>
      <el-table-column v-for="column in columns" :key="getColumnFieldFromColumn(column)" label-class-name="filter-cell">
        <template v-if="!disableFiltering" slot="header" slot-scope="{}">
          <el-input
            v-if="getFilterTypeFromColumn(column) === 'search'"
            v-model="search[getColumnFieldFromColumn(column)]"
            class="search"
            size="mini"
            :placeholder="$t('table.searchPlaceholder')"
            clearable
            :data-cy="`search-${getColumnFieldFromColumn(column)}`"
            prefix-icon="el-icon-search"
          />
          <el-select
            v-if="getFilterTypeFromColumn(column) === 'select'"
            v-model="search[getColumnFieldFromColumn(column)]"
            class="search-select"
            size="mini"
            :placeholder="$t('table.searchSelectPlaceholder')"
            clearable
          >
            <el-option
              v-for="item in column.filter.options"
              :key="item[column.filter.valueField]"
              :label="item[column.filter.labelField]"
              :value="item[column.filter.valueField]"
          /></el-select>
          <el-date-picker
            v-if="getFilterTypeFromColumn(column) === 'date'"
            v-model="search[getColumnFieldFromColumn(column)]"
            type="date"
            class="search"
            :placeholder="$t('table.searchDatePlaceholder')"
            size="mini"
            :format="dateFormat"
            :value-format="dateFormat"
            clearable
            style="width: 100%"
          />
        </template>
        <el-table-column
          :prop="getColumnFieldFromColumn(column)"
          :sortable="!disableSorting && (column.sortable === undefined ? 'custom' : column.sortable)"
          :label="$t(column.label || 'common.' + getColumnFieldFromColumn(column))"
          :align="column.align || defaultCellAlign"
          :width="column.width"
          :min-width="column.minWidth"
        >
          <template slot-scope="scope">
            <div :class="getClassFromColumn(column, scope.row)">
              <span v-if="!column.type">{{ getFormattedValueFromColumn(column, scope.row) }}</span>
              <span v-if="column.type === 'dateTime'">
                <date-time-picker
                  :value="getFormattedValueFromColumn(column, scope.row)"
                  :format="dateTimeFormat"
                  disabled
                />
              </span>
              <span v-if="column.type === 'date'">
                <date-time-picker
                  :value="getFormattedValueFromColumn(column, scope.row)"
                  :format="dateFormat"
                  disabled
                />
              </span>
              <span v-if="column.type === 'currency'">
                <numeric-input :value="getFormattedValueFromColumn(column, scope.row)" :precision="2" readonly />
              </span>
              <span v-if="column.type === 'percentage'">
                <numeric-input :value="getFormattedValueFromColumn(column, scope.row)" :precision="2" readonly />
              </span>
              <span v-if="column.type === 'duration'">
                <duration-input :value="getFormattedValueFromColumn(column, scope.row)" readonly />
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-if="allowActions"
        align="center"
        :label="$t('table.actions')"
        :width="actionsColumnWidth"
        min-width="75"
      >
        <template slot-scope="scope">
          <slot name="actions" v-bind="scope" />
          <el-button
            v-if="allowEdit && hasEditPermission"
            type="primary"
            data-cy="edit"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          />
          <el-button
            v-if="allowDelete && hasDeletePermission"
            :disabled="computeDisabledDelete(scope.row)"
            type="danger"
            icon="el-icon-delete"
            data-cy="delete"
            circle
            @click="deleteConfirm(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <context-menu ref="contextMenu" :options="contextMenuOptions" @option-clicked="handleContextMenuOptionClicked" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import EditorButton from './EditorButton';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import NumericInput from '@/components/DSE/NumericInput';
import DurationInput from '@/components/DSE/DurationInput';
import { hasCreatePermissionForCode, hasDeletePermissionForCode, hasEditPermissionForCode } from '@/utils/permission';
import { hasValue, tryGetValue } from '@/utils';
import { entityToStore } from '@/utils/store';
import { closeCurrentTag } from '@/utils/tags';
import ContextMenu from '@/components/DSE/ContextMenu';

const debounce = require('lodash.debounce');

import CrudTablePersistentState from './CrudTablePersistentState';

export default {
  name: 'CrudTable',
  components: {
    Pagination,
    EditorButton,
    DateTimePicker,
    NumericInput,
    DurationInput,
    ContextMenu
  },
  props: {
    allowActions: {
      default: true,
      type: Boolean
    },
    allowCreate: {
      default: true,
      type: Boolean
    },
    allowDelete: {
      default: true,
      type: Boolean
    },
    allowEdit: {
      default: true,
      type: Boolean
    },
    disableFiltering: {
      default: false,
      type: Boolean
    },
    disableSorting: {
      default: false,
      type: Boolean
    },
    columns: {
      /**
       * Array of Objects, Strings
       * Object keys: field: String, label: String, type: String, sortable: Boolean
       *  */
      default() {
        return {};
      },
      type: Array
    },
    entity: {
      default: '',
      type: String
    },
    onRowDblClick: {
      type: Function
    },
    onOpenContextMenu: {
      type: Function
    },
    onContextMenuOptionClicked: {
      type: Function
    },
    onCreate: {
      type: Function
    },
    onEdit: {
      type: Function
    },
    requiresDomainId: {
      default: false,
      type: Boolean
    },
    defaultFilters: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    generateSummary: {
      type: Function,
      default: ({ id, name, code, description, reference }) => {
        if (name === undefined) {
          let r = `${code || id}`;
          if (description) {
            r += ` (${description})`;
          }
          return r;
        }
        if (reference || code || description) {
          return `${name} (${reference || code || description})`;
        }
        return `${name}`;
      }
    },
    itemFilter: {
      type: Function,
      default: () => true
    },
    queryExtra: {
      type: Object,
      default: () => ({})
    },
    usePagination: {
      type: Boolean,
      default: true
    },
    inactiveID: {
      type: Number,
      default: 0
    },
    isEmbedded: {
      type: Boolean,
      default: false
    },
    persistState: {
      type: Boolean,
      default: true
    },
    permissionCode: [String, Array],
    actionsColumnWidth: {
      type: Number,
      default: 100
    },
    summaryMethod: {
      type: Function
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    /**
     * Returns the disabled state of delete button
     * @param {Object} row Owner row of the delete button
     * @returns {Boolean}
     */
    isDisabledDelete: Function
  },
  data() {
    return {
      entityStoreName: entityToStore(this.entity),
      loadedData: { items: [], total: 0 },
      search: this.persistentState.getDefaultSearch(
        this.filters,
        this.$route.params.filters || {},
        this.defaultFilters
      ),
      page: this.persistentState.getDefaultListQuery().page,
      limit: this.persistentState.getDefaultListQuery().limit,
      sort: this.persistentState.getDefaultListQuery().sort,
      total: 0,
      defaultCellAlign: 'left',
      contextMenuOptions: []
    };
  },
  computed: {
    dateFormat() {
      return this.$store.getters.dateFormat;
    },
    dateTimeFormat() {
      return this.$store.getters.dateTimeFormat;
    },
    innerPermissionCode() {
      return (
        this.permissionCode ||
        tryGetValue(this.$store.state, entityToStore(this.entity), 'permissionName') ||
        this.entity
      );
    },
    hasEditPermission() {
      return hasEditPermissionForCode(this.innerPermissionCode, this.$store.getters.permissions);
    },
    hasCreatePermission() {
      return hasCreatePermissionForCode(this.innerPermissionCode, this.$store.getters.permissions);
    },
    hasDeletePermission() {
      return hasDeletePermissionForCode(this.innerPermissionCode, this.$store.getters.permissions);
    },
    dontUseStore() {
      return !this.allowActions;
    },
    items() {
      const loadedItems = this.dontUseStore
        ? this.loadedData.items
        : this.$store.getters[this.entityStoreName + '/' + this.storeRetrieveItemsGetter];
      return loadedItems.filter(item => this.itemFilter(item, this));
    },
    listParams() {
      const params = this.requiresDomainId ? { domainID: this.$store.getters.domainID } : {};
      params.query = { ...this.filters, ...this.queryExtra };

      if (this.usePagination) {
        params.query = { ...params.query, ...this.listQuery };
      }
      if (this.usePagination && this.page) {
        params.query.page = this.page;
      }
      if (this.usePagination && this.limit) {
        params.query.limit = this.limit;
      }
      if (this.sort) {
        params.query.sort = this.sort;
      }

      if (this.inactiveID > 0) {
        params.query.includeID = this.inactiveID;
      }

      if (Object.keys(this.search).length > 0) {
        const formattedSearch = { ...this.search };
        this.columns.forEach(column => {
          if (column && column.field && column.type === 'currency' && formattedSearch[column.field]) {
            formattedSearch[column.field] = formattedSearch[column.field].replace(',', '.');
          }
        });
        params.query = { ...params.query, ...formattedSearch };
      }
      params.options = { dontSave: this.dontUseStore };
      return params;
    },
    loading() {
      return this.$store.getters[this.entityStoreName + '/isLoading'];
    },
    numberOfItems() {
      return this.dontUseStore ? this.loadedData.total : this.$store.getters[this.entityStoreName + '/totalItemsCount'];
    },
    storeFetchListAction() {
      if (this.usePagination) {
        return this.dontUseStore ? 'getPaginatedItems' : 'replacePaginatedItems';
      }
      return this.dontUseStore ? 'getItems' : 'replaceItems';
    },
    storeRetrieveItemsGetter() {
      if (this.usePagination) {
        return 'loadedPaginatedItems';
      }
      return 'loadedItems';
    },
    formatters() {
      return this.columns.reduce((acc, column) => {
        acc[column.field || column] = column.formatter || ((row, field) => row[field]);
        return acc;
      }, {});
    },
    filtersActive() {
      return Object.keys(this.search).some(k => hasValue(this.search[k]));
    },
    clearFiltersVisible() {
      return this.filtersActive && (!this.disableFiltering || !this.disableSorting);
    }
  },
  watch: {
    search: {
      deep: true,
      handler: function(value) {
        this.page = 1;
        this.debouncedUpdateSearch();
      }
    },
    filters(filters) {
      Object.keys(filters).forEach(key => {
        this.$set(this.search, key, filters[key]);
      });
    },
    listParams(listParameters) {
      this.persistentState.persistListParams(listParameters);
    }
  },
  beforeCreate() {
    const { entity, persistState } = this.$options.propsData;
    const entityId = (this.$route && this.$route.params && this.$route.params.id) || '';
    const userId = this.$store.state.authentication.userId;

    this.persistentState = new CrudTablePersistentState();
    this.persistentState.setNameSpace(`${entity}_${entityId}_${userId}`);
    if (persistState !== undefined) {
      this.persistentState.enable(persistState);
    } else {
      this.persistentState.enable(true);
    }
  },
  created() {
    this.refreshList();
    this.debouncedUpdateSearch = debounce(this.refreshList, 500);

    this.$store.dispatch('registerOnCloseView', { view: this.$route, hook: this.persistentState.getCleanupFunc() });
  },
  methods: {
    getFilterTypeFromColumn(column) {
      const { filterable, filter } = column;
      if (filterable === false) {
        // Not filterable if explicitly set so
        return undefined;
      }
      const givenType = filter && filter.type;
      return givenType || 'search';
    },
    getFormattedValueFromColumn(column, row) {
      const field = this.getColumnFieldFromColumn(column);
      return this.formatters[field](row, field, row[field]);
    },
    getColumnFieldFromColumn(column) {
      return column.field || column;
    },
    getClassFromColumn(column, row) {
      const classDefinition = column && column.class;
      if (typeof classDefinition === 'function') {
        return classDefinition(row);
      }
      return classDefinition;
    },
    computeDisabledDelete(row) {
      if (this.isDisabledDelete) {
        return this.isDisabledDelete(row);
      }
      return false;
    },
    deleteConfirm(row) {
      const { id } = row;
      const summary = this.generateSummary(row);
      this.$store
        .dispatch('notify/deleteConfirm', summary)
        .then(() => {
          this.$store
            .dispatch(this.entityStoreName + '/deleteItem', {
              id: id
            })
            .then(() => {
              this.$store.dispatch('notify/deleteCompleted');
            });
        })
        .catch(() => {
          this.$store.dispatch('notify/deleteCancelled');
        });
    },
    handleCreate() {
      this.onCreate ? this.onCreate() : this.$router.push({ name: 'Create' + this.entity });
      this.$emit('create');
    },
    handleEdit(row) {
      if (this.allowEdit && this.hasEditPermission) {
        const { id } = row;
        this.onEdit ? this.onEdit(row) : this.$router.push({ name: 'Edit' + this.entity, params: { id } });
        this.$emit('edit', id);
      }
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.$emit('current-change', currentRow);
    },
    openContextMenu(row, column, event) {
      if (this.onOpenContextMenu) {
        event.preventDefault();
        this.contextMenuOptions = this.onOpenContextMenu(row, column, event);
        this.$refs.contextMenu.showMenu(this.$el, event, row);
      }
    },
    handleContextMenuOptionClicked({ item, option }) {
      if (this.onContextMenuOptionClicked) {
        return this.onContextMenuOptionClicked(item, option);
      }
    },
    rowDblClick(row) {
      if (this.onRowDblClick) {
        this.onRowDblClick(row);
      } else {
        this.handleEdit(row);
      }
      this.$emit('input', row);
    },
    sortChange({ column, prop, order }) {
      if (prop !== null) {
        this.sort = order && (order === 'descending' ? prop + '_desc' : prop);
      }
      this.refreshList();
    },
    refreshList() {
      this.$store.dispatch(this.entityStoreName + '/' + this.storeFetchListAction, this.listParams).then(data => {
        this.loadedData.items = data.items;
        this.loadedData.total = data.total;
      });
    },
    clearAllFilters() {
      this.$refs.crudTable.clearFilter();
      this.search = {};
    },
    close() {
      closeCurrentTag(this);
    }
  }
};
</script>
<style lang="scss">
.crud-table {
  .page-buttons {
    .buttons-right,
    .buttons-left {
      display: inline-block;
    }
    .buttons-right {
      float: right;
    }
  }
  .edit-input {
    padding-right: 100px;
  }

  .cancel-btn {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .filter-cell > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .hide-overflow {
    .cell {
      white-space: nowrap;
    }
  }
  .search {
    .el-input__inner {
      padding-left: 25px;
      padding-right: 20px;
    }
    .el-input__prefix {
      left: 0;
    }
    .el-input__suffix {
      right: 0;
    }
  }
  .search-select {
    width: 100%;
    .el-input__inner {
      padding-left: 5px;
    }
    .el-input {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .el-input__suffix {
      top: -2px;
      right: 10px;
    }
  }
  .el-table .cell {
    word-break: break-word;
  }
}
</style>
